import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"
import Theme from "../../../theme/theme"
import Shape from "../../svg/shape"
import Arrow from "../../svg/arrow"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 18rem;
`

const IntroContent = styled.div`
  position: relative;
  font-size: 6.8rem;
  line-height: 8rem;
  padding-top: 11rem;

  h1,
  h2 {
    max-width: 95rem;
    font-size: 4rem;
    line-height: 4.5rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 8rem;
      line-height: 8rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
      margin-bottom: 15rem;
    }
  }

  p {
    max-width: 105rem;
    font-size: 3.8rem;
    line-height: 4rem;

    @media (max-width: ${props => props.theme.devices.desktop}) {
      font-size: 3rem;
      line-height: 3.4rem;
      margin-top: 3rem;
    }
  }
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: -16.4rem;
  right: 37%;
  width: 37.5rem;
  height: 37.5rem;
  transform: rotate(180deg);
  z-index: -1;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 21rem;
  right: 44%;
  width: 20rem;
  height: 20rem;
  transform: rotate(-90deg);
  z-index: -1;
`

const IntroText = styled.div`
  display: flex;
  flex-direction: column;
`

const ModulesColumns = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  color: ${props => props.theme.colors.invertedText};
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin-top: 6rem;

  h3 {
    font-size: 4.8rem;
    line-height: 5.2rem;
    font-family: "darker_grotesquebold", sans-serif;
    margin-bottom: 6rem;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 7.2rem;
    }
  }

  p {
    font-size: 2.8rem;
    line-height: 3.6rem;
    list-style-type: none;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 3.6rem;
      line-height: 4.8rem;
    }
  }

  ul {
    font-size: 2.8rem;
    line-height: 3.6rem;
    list-style-type: none;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 3.6rem;
      line-height: 4.8rem;
    }

    li {
      display: inline-flex;
      position: relative;
      padding: 0;
      margin-top: 5rem;

      a {
        color: ${props => props.theme.colors.invertedText};
      }

      svg {
        position: absolute;
        top: 1rem;
        right: -4rem;
        width: 2rem;
        transform: rotate(90deg);
        z-index: 1;

        path {
          fill: ${props => props.theme.colors.invertedText};
        }
      }
    }
  }
`

const ModuleLeftColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 55rem;
  background: ${props => props.theme.colors.third};
  padding: 17rem 8rem 30rem;
  margin: 0 1.2rem 20rem;
  border-radius: 40rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    max-width: 76rem;
    margin-bottom: 10rem;
    padding: 17rem 15rem 30rem;
  }

  .images-container {
    display: flex;

    > div {
      position: relative;
      overflow: hidden;
      height: 15rem;
      min-width: 10rem;
      z-index: 1;
      margin-bottom: 2rem;
      padding: 0 1rem;

      img {
        object-fit: contain !important;
      }
    }
  }
`

const ModuleRightColumn = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  max-width: 55rem;
  background: ${props => props.theme.colors.main};
  padding: 17rem 8rem;
  margin: 0 1.2rem 10rem;
  border-radius: 40rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    padding: 17rem 15rem;
    max-width: 76rem;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const IntroSection = ({ moduleData }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const image = moduleData.leftColumn.references.find(
          reference => reference.contentful_id === node.data.target.sys.id,
        )

        return <StyledGatsbyImage image={image} />
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        if (!children) return

        return (
          <ul>
            {children.map((child, index) => (
              <li key={index}>
                {child.props.children}
                <Arrow />
              </li>
            ))}
          </ul>
        )
      },
    },
  }

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.1}
    >
      <IntroContent>
        {moduleData.introText && (
          <IntroText>
            {documentToReactComponents(JSON.parse(moduleData.introText.raw))}
          </IntroText>
        )}
        {(moduleData.leftColumn || moduleData.rightColumn) && (
          <ModulesColumns>
            {moduleData.leftColumn && (
              <>
                <ModuleLeftColumn>
                  {documentToReactComponents(
                    JSON.parse(moduleData.leftColumn.raw),
                    options,
                  )}

                  {moduleData.modules && (
                    <div className="images-container">
                      {moduleData.modules.map((module, index) => (
                        <GatsbyImage image={module} key={index} />
                      ))}
                    </div>
                  )}
                </ModuleLeftColumn>
              </>
            )}
            {moduleData.rightColumn && (
              <ModuleRightColumn>
                {documentToReactComponents(
                  JSON.parse(moduleData.rightColumn.raw),
                )}
              </ModuleRightColumn>
            )}
          </ModulesColumns>
        )}
        <StyledShape1 />
        <StyledShape2 />
      </IntroContent>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  moduleData: PropTypes.object,
}

export default IntroSection
