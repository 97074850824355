import React from "react"
import IntroSection from "./intro-section"
import ContactSection from "../../common/contact/contact-section"
import Theme from "../../../theme/theme"

const ModulesContent = ({ pageContext }) => {
  return (
    <>
      <IntroSection moduleData={pageContext.modulePage} />
      {pageContext.modulePage.contact && (
        <ContactSection
          content={pageContext.modulePage.contact}
          sectionColor={Theme.colors.gray2}
          contactPageSlug={pageContext.contactPageSlug}
        />
      )}
    </>
  )
}

export default ModulesContent
