import React from "react"
import Viewport from "../../components/common/viewport"
import ModulesContent from "../content/modules/modules-content"
import SEO from "../seo"

const ModulesPage = props => {
  const page = props.pageContext.modulePage

  if (!page) return

  return (
    <Viewport>
      <ModulesContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default ModulesPage

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.modulePage.seoTitle}
    description={pageContext.modulePage.seoDescription}
    keywords={pageContext.modulePage.seoKeywords}
  />
)
